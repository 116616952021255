@font-face {
    font-family: 'RobotoCondensed-Regular';
    src: url('../assets/fonts/RobotoCondensed-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoCondensed-Bold';
    src: url('../assets/fonts/RobotoCondensed-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

:root {
    // --main-color: #5495ce;
    --main-color: #0295d2;
    --light-gray: #efefef;
    --dark-gray: #4f4f4f;
    --black: #1f1f1f;
    --white: #fff;
    --main-font: 'RobotoCondensed-Regular';
    --main-font-bold: 'RobotoCondensed-Bold';
}
